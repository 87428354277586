<template>
    <div :class="activeIndex == 0 ? 'rcheck_chart rcheck_chart_flex' : 'rcheck_chart'">
        <div class="rcheck_chart_table" :style="{ height: activeIndex == 0 ? '200px' : 'auto' }"
            v-if="rcheckTableData.length > 0">
            <Table :data="rcheckTableData" :articlesIndex="articlesIndex" @change:articleIndex="changeArticleIndex"></Table>
        </div>

        <div :class="activeIndex == 0 ? 'rcheck_chart_chart_one rcheck_chart_chart' : 'rcheck_chart_chart'">
            <Chart1 v-if="activeIndex == 0" :data="data"></Chart1>
            <Chart2 v-if="activeIndex == 1" :data="data"></Chart2>
            <Chart3 v-if="activeIndex == 2" :data="data"></Chart3>
            <Chart4 v-if="activeIndex == 3" :data="data"></Chart4>
        </div>
    </div>
</template>

<script>
import { pageEditor, tableDataEnum } from "../data";
import { pageEditorCN } from "../data-cn"
import Table from "./table.vue"
import Chart1 from "./chart1.vue"
import Chart2 from "./chart2.vue"
import Chart3 from "./chart3.vue"
import Chart4 from "./chart4.vue"

export default {
    emits: ['change:articleIndex'],
    props: {
        activeIndex: {
            type: Number,
            default: 0
        },
        articlesIndex: {
            type: Number,
            default: 0
        },
        data: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        Table,
        Chart1,
        Chart2,
        Chart3,
        Chart4
    },
    data() {
        const { result } = pageEditor
        const { result: resultCN } = pageEditorCN

        return {
            result,
            resultCN,
            tableDataEnum,
            rcheckTableData: []
        }
    },
    methods: {
        setRcheckTableData() {
            this.rcheckTableData = this.$t('result.table')[this.activeIndex].map((item, index) => {
                return {
                    title: item,
                    value: this.activeIndex == 1 ? 0 : this.data.articleStatisticsDTO[this.tableDataEnum[this.activeIndex][index]]
                }
            })
        },
        changeArticleIndex(index) {
            this.$emit('change:articleIndex', index)
        }
    },
    mounted() {
        console.log(this.$store.state.lang);

        this.setRcheckTableData()
    },
}
</script>

<style lang='scss'></style>