<template>
    <div class="rcheck_table">
        <div v-for="(item, index) in data" :key="index" :class="articlesIndex == index ? 'rcheck_table_item_active rcheck_table_item' : 'rcheck_table_item'"
         @click="changeArticleIndex(index)">
            <div class="rcheck_table_item_title">{{ item.title }}</div>
            <div class="rcheck_table_item_value">{{ item.value }}</div>
        </div>
    </div>
</template>

<script>
export default {
    emits: ['change:articleIndex'],
    props: {
        data: {
            type: Array,
            default: () => []
        },
        articlesIndex: {
            type: Number,
            default: 0
        }
    },
    methods: {
        changeArticleIndex(index) {
            this.$emit('change:articleIndex', index)
        }
    }
}
</script>

<style lang='scss'></style>