<template>
    <div class="rcheck_chart_references_box_list_content">
        <div v-for="(item, index) in data" :key="index" class="rcheck_chart_references_box_list_content_item">
            <img v-if="item.wos == 'yes'"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAMCAMAAABcOc2zAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAALRQTFRF3d3dxMTElJSU9fX1gYGB7+/vhISEoqKin5+fg4ODlZWVgoKC9PT08PDw2tra+Pj4kJCQmZmZvLy8xsbGvb291NTU5OTkh4eH09PTq6urnZ2dpKSkoKCgtra2t7e3xcXFioqK4ODgtbW18vLy19fX0tLS5ubmkZGR2NjYwMDAsLCwk5OTtLS05+fnr6+vnp6elpaWiIiIycnJs7Oz4eHhjo6O7e3tsbGxhoaG/Pz8+/v7gICA8hSgHAAAAJ9JREFUeNoczudSAlEQROHZAJJXEcxEFZVgAt07fd7/vRj41V1fTU21PaSWu1tKmaSPlNsn9NxruAr4pmV3YO4baBcqumTWh8wbNBMDGSzM37j3V7ZDpprRrMyXdOLF+4pL1VzI/IV2P+df0PjiOuAZ6rKU/tj/cAjwnDJONaFLqk6wBn6lQcSTTjCOtpOKiNEZevPUifV6TLc30lGAAQCVXyCUtEvdxQAAAABJRU5ErkJggg==">
            <div v-else style="width: 36px; min-width: 36px; height: 14px;"></div>

            <div>
                <span class="storng_text">{{ index + 1 }}</span>.
                {{ item.author }} {{ item.articleTitle }}<span>
                    {{ item.journalName }}</span> <span class="storng_text">{{ item.year }}</span>
                <span v-if="item.page != ''">,{{ item.page }}</span>
                <span v-if="item.doi != ''">, DOI: {{ item.doi }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {

        }
    }
}
</script>

<style lang='scss'></style>