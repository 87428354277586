<template>
    <div class="rcheck_contant_box">
        <div @click="changeComponent" class="rcheck_contant_back">
            <i class="el-icon-arrow-left"></i>
            <span>{{ $t('result.backBtn') }}</span>
        </div>

        <div class="rcheck_contant">
            <div>
                <Tab :activeIndex="activeIndex" @change:index="changeIndex"></Tab>
            </div>

            <div class="rcheck_gist_box">
                <div class="rcheck_gist">
                    <div class="rcheck_gist_left">
                        <div class="rcheck_gist_left_total">{{ gistData.total }}</div>
                        <div class="rcheck_gist_left_tab">{{ $t('result.totalList')[activeIndex] }}</div>
                        <div class="rcheck_gist_left_btn">{{ $t('result.btn') }}</div>
                    </div>
                    <div class="rcheck_gist_right">
                        <div class="rcheck_gist_right_describe">{{ $t(`result.describeList[${activeIndex}]`,
                            gistData.describeTotal)
                            }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="rcheck_chart_box">
                <Chart :activeIndex="activeIndex" :articlesIndex="articlesIndex" :data="data" ref="chartRef"
                    @change:articleIndex="changeArticleIndex">
                </Chart>
            </div>

            <div class="rcheck_chart_articles_list">
                <div
                    v-if="activeIndex != 1 && data.frontData[tableArticlesEnum[activeIndex][articlesIndex]].length != 0">
                    {{ $t('result.article') }}:</div>
                <Articles :data="data.frontData[tableArticlesEnum[activeIndex][articlesIndex]]"></Articles>
            </div>
        </div>
    </div>
</template>

<script>
import { pageEditor, tableArticlesEnum } from "./data";
import Tab from "./components/tab.vue"
import Gist from "./components/gist.vue"
import Chart from "./components/chart.vue"
import Articles from "./components/articles.vue";

export default {
    emits: ["change:component"],
    props: {
        data: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        Tab,
        Gist,
        Chart,
        Articles
    },
    data() {
        const { result } = pageEditor

        return {
            activeIndex: 0,
            articlesIndex: 0,
            gistData: {},
            result,
            tableArticlesEnum
        }
    },
    methods: {
        changeIndex(index) {
            this.activeIndex = index
            this.articlesIndex = 0

            if (this.activeIndex === 0) {
                this.gistData.total = this.data.articleStatisticsDTO.count
                this.gistData.describeTotal = {
                    a: this.data.articleStatisticsDTO.count, b: this.data.articleStatisticsDTO.journalCount, c: this.data.articleStatisticsDTO.count - this.data.articleStatisticsDTO.journalCount
                }
            } else if (this.activeIndex === 1) {
                this.gistData.total = this.data.articleStatisticsDTO.retractionCount
                this.gistData.describeTotal = {
                    a: this.data.articleStatisticsDTO.count, b: this.data.articleStatisticsDTO.journalCount, c: this.data.articleStatisticsDTO.count - this.data.articleStatisticsDTO.journalCount
                }
            } else if (this.activeIndex === 2) {
                this.gistData.total = this.data.articleStatisticsDTO.journalCount
                this.gistData.describeTotal = {
                    a: this.data.articleStatisticsDTO.wosCount, b: this.data.articleStatisticsDTO.scopusCount, c: this.data.articleStatisticsDTO.normalJournalCount
                }

            } else if (this.activeIndex === 3) {
                this.gistData.total = this.data.map.total
                this.gistData.describeTotal = {
                    a: this.data.map.twoTotal, b: this.data.map.ttofTotal, c: this.data.map.fiveTotal
                }
            }

            this.$nextTick(() => {
                this.$refs.chartRef.setRcheckTableData()
            })
        },
        changeArticleIndex(index) {
            this.articlesIndex = index

            const element = document.querySelector('.rcheck_chart_articles_list')

            if (element) {
                const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;

                window.scrollTo({
                    top: elementPosition - 100,
                    behavior: 'smooth',
                });
            }
        },
        changeComponent() {
            this.gistData = {}
            this.$emit("change:component", "Upload")
        }
    },
    mounted() {
        this.changeIndex(1)
        this.changeIndex(0)
    }
}
</script>

<style lang='scss'>
@import "./style.scss";
</style>