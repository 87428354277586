export const chartColor = ['#f3a077', '#d55a68']
export const loopColor = ['#f3a077', '#d55a68', '#ffc651']

export const getChartCakeOption = (chartData, chartColors) => {
    return {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            top: '0%',
            left: 'center'
        },
        series: [
            {
                type: 'pie',
                radius: ['30%', '70%'],
                center: ['50%', '55%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: chartData,
                label: {
                    position: "inner",
                    show: true,
                    formatter: "{d}%",
                    color: "#fff",
                    fontSize: 14
                },
                itemStyle: {
                    color: (params) => {
                        return chartColors[params.dataIndex];
                    }
                }
            }
        ]
    };
};

export const getChartLoopOption = (chartData, chartColors) => {
    return {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left',
        },
        series: [
            {
                type: 'pie',
                radius: '80%',
                data: chartData,
                top: '60px',
                emphasis: {
                    itemStyle: {
                        fontWeight: 'bold',
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                label: {
                    position: 'inner',
                    show: true,
                    formatter: '{d}%',
                    color: "#fff",
                    fontSize: 16
                },
                itemStyle: {
                    color: (params) => {
                        return chartColors[params.dataIndex];
                    }
                }
            }
        ]
    }
}