// recheck 组件中文语言包
export const pageEditorCN = {
    upload: {
        title: '开始上传，揭开论文的神秘面纱',
        stepList: [
            "上传文献",
            "开始运行",
            "分析文献",
            "自动生成报告",
            "导出下载"
        ],
        desc: 'ReCheck 是一款创新的 AI 驱动的参考文献检查工具，专为学术期刊设计，为研究人员提供了一种简化的解决方案，确保引用的准确性。该先进应用允许用户上传 PDF 或 Word 格式的手稿进行全面的参考文献检查。如果引用了已撤回的文章，我们会提醒您注意。',
        uploadDescribe: "点击或拖拽上传",
        btn: '立即运行',
        notUploadedTip: "请先上传文件",
        errorUploadType: "文件格式不正确，请上传正确的文件",
        errorUpload: "上传失败，请重试",
        successUpload: "上传成功",
        errorAnalyze: "解析失败，请重试",
        successAnalyze: "文件解析成功",
    },

    result: {
        tabList: [
            "基本统计结果",
            "问题文章 （撤销、勘误、争议等）",
            "期刊文献分布",
            "期刊文献年度分布"
        ],
        totalList: [
            "总引用",
            "问题文章 （撤销、勘误、争议等）",
            "期刊文章",
            "总引用"
        ],
        btn: "生成报告",
        backBtn: "返回",
        describeList: [
            "此论文参考文献统计结果为：共有参考文献 {a} 条，其中期刊文献 {b} 条，非期刊文献 {c} 条。",
            "此论文参考文献统计结果为：共有参考文献 {a} 条，其中期刊文献 {b} 条，非期刊文献 {c} 条。",
            "此论文期刊文献收录情况为：共有 WOS 收录 {a} 条，Scoupus 收录 {b} 条，普刊收录 {c} 条。",
            "此论文期刊文献年度分布情况为：共有近2年收录的文献 {a} 条，3-5 年收录文献 {b} 条，5 年以上收录文献 {c} 条。"
        ],
        table: [
            ["期刊文献", "非期刊文献"],
            [],
            ["WOS 收录", "SCOPUS 收录", "普刊收录"],
            ["过去2年收录的文献", "3-5 年收录文献", "5 年以上收录文献"]
        ],
        charts: [
            ["非期刊文献占比", "期刊文献占比"],
            "参考文献",
            [
                ["非 WOS 收录占比", "WOS 收录占比"],
                ["非 Scopus 收录占比", "Scopus 收录占比"]
            ],
            ["过去2年收录的文献占比", "3-5 年收录文献占比", "5 年以上收录文献占比"]
        ],
        article: "文章"
    },
};

export const tableDataEnum = [
    ['journalCount', 'nojournalCount'],
    [],
    ['wosCount', 'scopusCount', 'normalJournalCount'],
    ['twoTotal', 'ttofTotal', 'fiveTotal']
]