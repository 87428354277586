<template>
    <div class="rcheck_chart_references" v-if="data.map.RETRACTED.length > 0">
        <div class="rcheck_chart_references_box">
            <div class="rcheck_chart_references_box_list">
                <div>{{ $t('result.charts')[1] }}</div>
                <Articles :data="data.map.RETRACTED"></Articles>
            </div>
        </div>
    </div>
</template>

<script>
import { pageEditor } from "../data";
import Articles from "./articles.vue";

export default {
    props: {
        activeIndex: {
            type: Number,
            default: 0
        },
        data: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        Articles
    },
    data() {
        const { result } = pageEditor

        return {
            result,
        }
    },
}
</script>

<style lang='scss'></style>